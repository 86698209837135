export default {
    async getItems(relations, relations_count, search_text, filters_query) {
        try {
            const filter_request = await API.handleFilterRequest(
                relations,
                relations_count,
                search_text,
                filters_query
            );
            const roles_response = await API.apiClient.get("/employees/roles", {
                params: {
                    relations: filter_request.relations,
                    relations_count: filter_request.relations_count,
                    search_text: filter_request.search_text,
                    filters_query: filter_request.filters_query,
                },
            });

            if (
                roles_response &&
                roles_response instanceof Object &&
                roles_response.data
            ) {
                return roles_response.data;
            } else {
                return null;
            }
        } catch (error) {
            errorHandler(error);
        }
    },
};
