export default {
  async getItems(relations, relations_count, search_text, filters_query) {
    try {
      const filter_request = await API.handleFilterRequest(
        relations,
        relations_count,
        search_text,
        filters_query,
      );
      const employee_departments_response = await API.apiClient.get("/employees/departments", {
        params: {
          relations: filter_request.relations,
          relations_count: filter_request.relations_count,
          search_text: filter_request.search_text,
          filters_query: filter_request.filters_query,
        },
      });

      if (
        employee_departments_response &&
        employee_departments_response instanceof Object &&
        employee_departments_response.data
      ) {
        return employee_departments_response.data;
      } else {
        return null;
      }
    } catch (error) {
      errorHandler(error);
    }
  },
};
